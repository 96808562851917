import React from "react";
// import { CircularProgress } from "@mui/material";
import spinner from "../assets/images/spinner.gif";

const Loading = () => {
  // return (
  //   <div
  //     style={{
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //       height: "100vh",
  //     }}
  //   >
  //     <CircularProgress />
  //   </div>
  // );
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-white bg-opacity-70">
      <img src={spinner} alt="Loading spinner" className="w-20 h-20" />
    </div>
  );
};

export default Loading;
